const enum DomainEnum {
  old = 'http://local.4dot.cz', // old backend developing
  unicornBE = 'https://unicornbe.4dot.cz', // backend for UNICORN (beta deploy)
  y = 'https://4y.4dot.cz',
  i = 'https://4i.4dot.cz', // IDA
  newApi = 'http://adminapp.4dot.cz' // new backend
}

export const DOMAIN = DomainEnum.unicornBE;
export const ELECTRON_BUILD = false;

export const LANGUAGE = localStorage.getItem('locale') || 'cs';
export const RECAPTCHA_KEY = '6LfaqXAUAAAAAEGFCyzr7t1RO4MShrVRGExskFw9';
